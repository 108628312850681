.signIn-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.signIn-page form {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.01);
  width: 400px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signIn-page form img {
  width: 200px;
  margin: 10px 0 40px;
}

.signIn-page form p {
  color: #ff3333;
  margin-bottom: 15px;
  border: 1px solid #ff3333;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.signIn-page form input {
  height: 46px;
  margin-bottom: 15px;
  padding: 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

.signIn-page form input::placeholder {
  color: #999;
}

.signIn-page form input:hover {
  background: #F1F1F1;
}

.signIn-page form input:focus {
  border-radius: 5px;
  border: 1px solid #7EA329;
}

.signIn-page form button {
  color: #fff;
  font-size: 16px;
  background: #7EA329;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

.signIn-page form button:hover {
  cursor: pointer;
  background: #91BB2F;
}

.signIn-page form hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
}

.signIn-page form a {
  font-size: 16;
  font-weight: bold;
  color: #999;
  text-decoration: none;
}