@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
html {
  height: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

main {
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 60px;
  /* Height of the footer */
  height: 100%;
}

a {
  color: #7EA329;
}

a:hover {
  color: #634924;
}

label {
  font-weight: bold;
}

form input[type=text], form select {
  border-radius: 5px;
  height: 46px;
  padding: 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

form input[type=file] {
  height: 46px;
  color: #777;
  font-size: 15px;
  width: 100%;
}

textarea {
  padding: 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

textarea:hover {
  background: #F1F1F1;
}

form input::placeholder {
  color: #999;
}

form input[type=text]:hover {
  background: #F1F1F1;
}

form input:focus {
  border-radius: 5px;
  border: 1px solid #7EA329;
}

form button {
  color: #fff;
  font-size: 16px;
  background: #7EA329;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

form button:hover {
  cursor: pointer;
  background: #91BB2F;
}

h3 {
  display: inline;
}

.quill {
  background-color: white
}

.item-container {
  padding: 30px;
  background-color: white;
  border: 1px solid #ddd;
}

.item-row:not(:first-child) {
  line-height: 46px;
  color: #777;
  font-size: 15px;
}

.MuiTableCell-paddingNone>div {
  float: right;
}

form .removeButton {
  color: #fff;
  font-size: 16px;
  background: #FF3333;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 50px;
}

form .removeButton:hover {
  background: #FF5555;
}

form .addButton:hover, form .addButton {
  background: none;
  color: black;
}

label {
  margin-top: 10px;
}

#root {
  min-height: 100vh;
  position: relative;
  height: 100%;
}

.ql-editor {
  min-height: 200px;
}

.custom-dialog-ui {
  text-align: center;
  width: 400px;
  padding: 20px;
  background: #91BB2F;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-dialog-ui>h1 {
  margin-top: 0;
}

.custom-dialog-ui>button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.custom-dialog-ui>button:hover {
  width: 160px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  background: #634924;
  color: white;
  font-size: 14px;
}