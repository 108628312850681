@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
html {
  height: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

main {
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 60px;
  /* Height of the footer */
  height: 100%;
}

a {
  color: #7EA329;
}

a:hover {
  color: #634924;
}

label {
  font-weight: bold;
}

form input[type=text], form select {
  border-radius: 5px;
  height: 46px;
  padding: 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

form input[type=file] {
  height: 46px;
  color: #777;
  font-size: 15px;
  width: 100%;
}

textarea {
  padding: 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

textarea:hover {
  background: #F1F1F1;
}

form input::-webkit-input-placeholder {
  color: #999;
}

form input:-ms-input-placeholder {
  color: #999;
}

form input::-ms-input-placeholder {
  color: #999;
}

form input::placeholder {
  color: #999;
}

form input[type=text]:hover {
  background: #F1F1F1;
}

form input:focus {
  border-radius: 5px;
  border: 1px solid #7EA329;
}

form button {
  color: #fff;
  font-size: 16px;
  background: #7EA329;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

form button:hover {
  cursor: pointer;
  background: #91BB2F;
}

h3 {
  display: inline;
}

.quill {
  background-color: white
}

.item-container {
  padding: 30px;
  background-color: white;
  border: 1px solid #ddd;
}

.item-row:not(:first-child) {
  line-height: 46px;
  color: #777;
  font-size: 15px;
}

.MuiTableCell-paddingNone>div {
  float: right;
}

form .removeButton {
  color: #fff;
  font-size: 16px;
  background: #FF3333;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 50px;
}

form .removeButton:hover {
  background: #FF5555;
}

form .addButton:hover, form .addButton {
  background: none;
  color: black;
}

label {
  margin-top: 10px;
}

#root {
  min-height: 100vh;
  position: relative;
  height: 100%;
}

.ql-editor {
  min-height: 200px;
}

.custom-dialog-ui {
  text-align: center;
  width: 400px;
  padding: 20px;
  background: #91BB2F;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-dialog-ui>h1 {
  margin-top: 0;
}

.custom-dialog-ui>button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.custom-dialog-ui>button:hover {
  width: 160px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  background: #634924;
  color: white;
  font-size: 14px;
}
.navbar {
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 5px 0 rgba(0, 0, 0, 0.01);
  background: #fff;
  padding: 0px 1rem;
}

.navbar .logo {
  width: 60px;
  margin: 20px;
}

.navbar .nav-link:not(.active):hover, .active {
  background-color: #634924;
  color: #FFFFFF !important;
  font-size: 16px;
  line-height: 80px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.navbar .nav-link:not(.active) {
  color: #7EA329 !important;
  font-size: 16px;
  line-height: 80px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.navbar .nav-link i {
  vertical-align: middle;
}
footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
}
.signIn-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.signIn-page form {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.01);
  width: 400px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signIn-page form img {
  width: 200px;
  margin: 10px 0 40px;
}

.signIn-page form p {
  color: #ff3333;
  margin-bottom: 15px;
  border: 1px solid #ff3333;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.signIn-page form input {
  height: 46px;
  margin-bottom: 15px;
  padding: 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

.signIn-page form input::-webkit-input-placeholder {
  color: #999;
}

.signIn-page form input:-ms-input-placeholder {
  color: #999;
}

.signIn-page form input::-ms-input-placeholder {
  color: #999;
}

.signIn-page form input::placeholder {
  color: #999;
}

.signIn-page form input:hover {
  background: #F1F1F1;
}

.signIn-page form input:focus {
  border-radius: 5px;
  border: 1px solid #7EA329;
}

.signIn-page form button {
  color: #fff;
  font-size: 16px;
  background: #7EA329;
  height: 56px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

.signIn-page form button:hover {
  cursor: pointer;
  background: #91BB2F;
}

.signIn-page form hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
}

.signIn-page form a {
  font-size: 16;
  font-weight: bold;
  color: #999;
  text-decoration: none;
}
